import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import tw, { styled } from 'twin.macro'

const Background = styled.div`
  ${tw`bg-grey absolute top-0 left-0 w-full`}
  z-index: -10;
  height: 100vh;
`
class About extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Background></Background>
        <Helmet title="Willi Nuechterlein" />
        <h1>sorry, this page doesn't exist</h1>
      </Layout>
    )
  }
}

export default About
